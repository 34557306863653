import React from "react";
import Navbar from "../components/layout/Navbar";
import { Link } from "gatsby";

const Error = () => {
	return (
		<div>
			<Navbar />
			<div className="errorContainer">
				<div className="errorTextContainer">
					<p className="errorNo">404</p>
					<p className="errorText">Page Not Found</p>
				</div>
				<div className="errorLinkContainer">
					<Link to="/">Go Home</Link>
				</div>
			</div>
		</div>
	);
};

export default Error;
